<template>
  <div>
    <b-avatar-group
        size="35px"
        overlap="0"
    >
      <div
          v-for="grupo in itens"
          :key="`${grupo.uuid}-${prefix}`"
          class="d-flex"
      >
        <b-avatar
            :id="`popover-${grupo.uuid}-${prefix}`"
            v-b-tooltip.hover.top="popoverDisabled && nomeFuncao(grupo)"
            size="32"
            class="pull-up"
            :src="`${grupo.imagem}`"
            :variant="`light-secondary`"
            :badge="badgetGroup.text"
            :badge-variant="badgetGroup.variant"
            :text="avatarText(grupo.nome)"
        />
        <b-popover
            v-if="!popoverDisabled"
            :target="`popover-${grupo.uuid}-${prefix}`"
            placement="top"
            triggers="hover"
            @show="onShow(grupo.uuid)"
        >
          <template #title>
            {{ grupo.nome ? grupo.nome : grupo.name }}
          </template>
          <div>
            <div
                v-if="loading"
                class="d-flex align-items-center justify-content-center"
            >
              <b-spinner class="text-secondary"/>
            </div>
            <b-avatar-group
                v-else
                size="35px"
            >
              <b-avatar
                  v-for="integrante in listaIntegrantes"
                  :key="integrante.uuid"
                  v-b-tooltip.hover.top="nomeFuncao(integrante)"
                  size="32"
                  class="pull-up"
                  :src="integrante.imagem"
                  :variant="`light-primary`"
                  :text="avatarText(integrante.name)"
              />
            </b-avatar-group>
          </div>
        </b-popover>
      </div>
    </b-avatar-group>
  </div>
</template>

<script>
import { BAvatar, BAvatarGroup, BPopover, BSpinner, VBPopover, VBTooltip, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'

export default {
  name: 'PopoverIntegrantes',

  components: {
    BAvatar,
    BAvatarGroup,
    BPopover,
    BSpinner,
  },

  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    popoverInfos: {
      type: Object,
      default: () => ({}),
    },
    popoverDisabled: {
      type: Boolean,
      default: false,
    },
    itens: {
      type: Array,
      default: () => ([]),
    },
    prefix: {
      type: String,
      default: '',
    },
    urlBase: {
      type: String,
      default: '',
    },
    badgetGroup: {
      type: Object,
      default: {
        text: '',
        variant: '',
      },
    },
  },
  data() {
    return {
      loading: false,
      disabled: false,
      listaIntegrantes: [],

    }
  },
  methods: {
    onShow(idPesquisa) {
      this.fetch(idPesquisa)
    },

    avatarText(value) {
      if (!value) {
        return ''
      }

      const nameArray = value.split(' ')
          .slice(0, 2)
      const initials = nameArray.map(word => word.charAt(0)
          .toUpperCase())
          .join('')

      return initials
    },
    nomeFuncao(item) {

      const nome = item.nome ? item.nome : item.name
      return `${nome}`
    },
    fetch(idPesquisa) {
      this.loading = true
      axios
          .get(`times/${idPesquisa}/users`)
          .then(response => {
            this.loading = false
            this.listaIntegrantes = response.data
          })
    },
  },
}
</script>
